import { useState } from 'react';

import { Link, mq, styled, useInterval, useModal, useOneTrust } from '@asicsdigital/oneasics-blocks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useLegalUrls } from '../../hooks/use-legal-urls';
import { ONETRUST_DATA_DOMAIN_SCRIPT } from '../../utilities/constants';

export const FooterLink = styled(Link)(
  mq({
    marginLeft: ['3px', '5px', '7px', '10px'],
    marginRight: ['3px', '5px', '7px', '10px'],
    textDecoration: 'none',
  }),
);

export const ContactSupportLink = styled(Link)(
  mq({
    marginLeft: '3px',
    marginRight: '3px',
    textDecoration: 'none',
    display: ['block', 'block', 'none'],
  }),
);

const FooterBody = () => {
  const [ isModalOpen, setModalOpen ] = useState(false);
  const { asicsTermsUrl, ccpaUrl, cookiePolicyUrl, oneasicsTermsUrl, privacyPolicyUrl, supportUrl } = useLegalUrls();
  const location = useLocation();

  const onClose = () => {
    setModalOpen(false);
  };
  const { openModal, RenderModal } = useModal(onClose);

  const {
    client: { clientId },
    queryParams: { webview },
  } = useSelector((state) => state);
  const { i18n, t } = useTranslation(['overrides', 'footer']);

  const { hydrateCookiePolicyModal, modalContentHydrated } = useOneTrust({
    dataDomainScript: ONETRUST_DATA_DOMAIN_SCRIPT,
    locale: i18n.language,
    view: location.pathname,
    webview: webview ?? true,
  });

  // Attempt to populate the modal until we've verified it has succeeded.
  useInterval({ callback: hydrateCookiePolicyModal, delay: isModalOpen ? 300 : null, maxTries: 10, stopTest: modalContentHydrated });

  const openAndHydrateCookiePolicyModal = () => {
    setModalOpen(true);
    openModal();
  };

  return (
    <>
      {clientId === 'runkeeper' && (
        <ContactSupportLink
          newTab
          fontSize="xxxSmall"
          id="footer-rk-support"
          linkType="external"
          name="footer-rk-support"
          path={supportUrl}
          tabIndex={0}
        >
          {t('rkSupport')}
        </ContactSupportLink>
      )}
      <FooterLink
        newTab
        fontSize="xxxSmall"
        id="footer-privacy-policy"
        linkType="external"
        name="footer-privacy-policy"
        path={privacyPolicyUrl}
        tabIndex={0}
      >
        {t('privacyPolicy')}
      </FooterLink>
      <FooterLink
        newTab
        fontSize="xxxSmall"
        id="footer-terms-and-conditions"
        linkType="external"
        name="footer-terms-and-conditions"
        path={i18n.language === 'ja-JP' ? oneasicsTermsUrl : asicsTermsUrl}
        tabIndex={0}
      >
        {t('termsOfService')}
      </FooterLink>
      {i18n.language !== 'ko-KR' && i18n.language !== 'ja-JP' && i18n.language !== 'en-US' && (
        <FooterLink
          fontSize="xxxSmall"
          id="footer-cookie-policy"
          name="footer-cookie-policy"
          path=""
          tabIndex={0}
          onClick={openAndHydrateCookiePolicyModal}
        >
          {t('cookiePolicy')}
        </FooterLink>
      )}
      {i18n.language === 'en-US' && (
        <FooterLink
          newTab
          fontSize="xxxSmall"
          id="footer-cookie-policy-us"
          linkType="external"
          name="footer-cookie-policy-us"
          path={cookiePolicyUrl}
          tabIndex={0}
        >
          {t('cookiePolicy')}
        </FooterLink>
      )}
      {i18n.language !== 'ko-KR' &&
        i18n.language !== 'ja-JP' &&
        (!webview || (clientId !== 'runkeeper' && clientId !== 'asics_studio')) && (
          <FooterLink
            className="optanon-toggle-display"
            fontSize="xxxSmall"
            id="footer-cookie-settings"
            name="footer-cookie-settings"
            path=""
            tabIndex={0}
          >
            {t('cookieSettings')}
          </FooterLink>
        )}
      {ccpaUrl && (
        <FooterLink
          newTab
          fontSize="xxxSmall"
          id="footer-ca-privacy-notice"
          linkType="external"
          name="footer-ca-privacy-notice"
          path={ccpaUrl}
          tabIndex={0}
        >
          {t('caPrivacyNotice')}
        </FooterLink>
      )}
      <RenderModal>
        <div id="optanon-cookie-policy-holder" />
      </RenderModal>
    </>
  );
};

FooterBody.propTypes = {};

export default FooterBody;
